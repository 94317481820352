import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  IconButton,
  Typography,
  SvgIcon,
  Tab,
  Tabs,
  Button,
  Grid,
  FormControl,
  Dialog,
  DialogContent,
  DialogTitle,
  RadioGroup,
} from "@mui/material";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { useFormik } from "formik";
import {
  getCheckBox,
  getCheckBoxCognitive,
  getCheckBoxEnvironmental,
  getCheckBoxEnvironmentalMultilevel,
  physicalDemandLevels,
} from "../HypotheticalAnalyzer/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TransferableSkillFilterModel = ({
  open,
  handleClose,
  getFilters,
  handleSearch,
  appliedFilters,
  notes,
}) => {
  const [value, setValue] = useState(0);
  const [physicalTabHeight, setPhysicalTabHeight] = useState();
  const [cognitiveTabHeight, setCognitiveTabHeight] = useState();
  const [dotFilters, setDotFilters] = useState();

  const [physicalDemandData, setPhysicalDemandData] = useState([]);
  const [cognitiveDemandData, setCognitiveDemandData] = useState([]);
  const [environmentalDemandData, setEnvironmentalDemandData] = useState([]);
  const [activeButtonPhysical, setActiveButtonPhysical] = useState(
    appliedFilters?.Strength || []
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const PhysicalDemandsData = JSON.parse(
      localStorage.getItem("PhysicalDemands")
    );
    if (!PhysicalDemandsData) {
      fetch("DotPhysicalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPhysicalDemandData(data);
        return;
      });
    } else {
      setPhysicalDemandData(PhysicalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const EnvironmentalDemandsData = JSON.parse(
      localStorage.getItem("EnvironmentalDemands")
    );
    if (!EnvironmentalDemandsData) {
      fetch("EnvironmentalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEnvironmentalDemandData(data);
        return;
      });
    } else {
      setEnvironmentalDemandData(EnvironmentalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));
    if (!AptitudesData)
      fetch("aptitudes.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCognitiveDemandData(data);
        return;
      });
    else {
      setCognitiveDemandData(AptitudesData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setDotFilters(getFilters());
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;

      setPhysicalTabHeight(innerHeight - 389);
      setCognitiveTabHeight(innerHeight - 363);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  useEffect(() => {
    if (
      !!physicalDemandData.length &&
      !!cognitiveDemandData.length &&
      !!environmentalDemandData.length
    ) {
      const newValues = generateInitialValues(
        physicalDemandData,
        cognitiveDemandData,
        environmentalDemandData,
        appliedFilters
      );
      formik.setValues(newValues);
    }
  }, [physicalDemandData, environmentalDemandData, cognitiveDemandData]);

  const generateInitialValues = (
    physicalData,
    aptitudesData,
    environmentalData,
    appliedFilters
  ) => {
    const initialValues = {};
    const keys = Object.keys(appliedFilters);
    physicalData?.forEach((item) => {
      initialValues[item.Name] = keys?.includes(item.Name)
        ? appliedFilters[item.Name]
        : "";
    });

    aptitudesData?.forEach((item) => {
      initialValues[item.Description] = keys?.includes(item.Description)
        ? appliedFilters[item.Description]
        : "";
    });

    environmentalData?.forEach((item) => {
      initialValues[item.Description] = keys?.includes(item.Description)
        ? appliedFilters[item.Description]
        : "";
    });

    return initialValues;
  };

  const handlePhysicalDemand = (e, level) => {
    e.preventDefault();
    const selectedActiveButtonPhysical = [...activeButtonPhysical];
    if (selectedActiveButtonPhysical?.includes(level)) {
      selectedActiveButtonPhysical.splice(selectedActiveButtonPhysical.indexOf(level), 1);
    } else {
      selectedActiveButtonPhysical.push(level);
    }
    setActiveButtonPhysical(selectedActiveButtonPhysical);
    formik.setFieldValue("Strength", selectedActiveButtonPhysical);
  };

  const formik = useFormik({
    initialValues: generateInitialValues(
      physicalDemandData,
      cognitiveDemandData,
      environmentalDemandData,
      appliedFilters
    ),
    onSubmit: async (data) => {
      await handleSearch({ locallyAppliedFilters: data });
    },
  });

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" component={"h6"}>
              Sort by
            </Typography>
            <Box>
              <IconButton
                size="small"
                className="EyeIconButton"
                onClick={handleClose}
                sx={{ padding: "2px", borderRadius: "4px !important" }}
              >
                <SvgIcon
                  component={CrossIcon}
                  inheritViewBox
                  sx={{ height: "14px", width: "14px" }}
                />
              </IconButton>
            </Box>
          </Stack>
        </DialogTitle>

        {!!dotFilters && (
          <DialogContent sx={{ padding: 3 }}>
            <Grid item xs={6}>
              <Box className="bg-white" padding={2} borderRadius={"10px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">Filter</Typography>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="none"
                      sx={{
                        "& .MuiTabs-flexContainer": {
                          justifyContent: "flex-end",
                          gap: 2,
                        },
                      }}
                    >
                      <Tab
                        label="Physical"
                        {...a11yProps(0)}
                        sx={{
                          color:
                            value === 0
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 0 ? "#0089D7" : "#ffffff",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                          border: value === 0 ? "none" : "1px solid #B8B8BE",
                        }}
                      />
                      <Tab
                        label="Cognitive"
                        {...a11yProps(1)}
                        sx={{
                          color:
                            value === 1
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 1 ? "#0089D7" : "#ffffff",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                          border: value === 1 ? "none" : "1px solid #B8B8BE",
                        }}
                      />
                      <Tab
                        label="Environmental"
                        {...a11yProps(2)}
                        sx={{
                          color:
                            value === 2
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 2 ? "#0089D7" : "#ffffff",
                          minHeight: "20px",
                          paddingX: "15px",
                          paddingY: "10px",
                          alignItems: "end",
                          border: value === 2 ? "none" : "1px solid #B8B8BE",
                        }}
                      />
                    </Tabs>
                  </Box>
                </Stack>
                {/* physical Tab Component */}
                {/* <PhysicalTabs {...physicalProps} /> */}
                <form onSubmit={formik.handleSubmit}>
                  <TabPanel value={value} index={0}>
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                      sx={{
                        "& *": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          fontSize={"14px !important"}
                        >
                          Physical Demand Level
                        </Typography>
                      </Box>

                      <Box>
                        <Grid
                          container
                          rowGap={2}
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          {physicalDemandLevels.map((level, index) => (
                            <Grid item key={index}>
                              <button
                              style={{
                                marginRight: "15px",
                                border: "1px solid #B8B8BE",
                                backgroundColor: "#FFFFFF",
                                color: "#1A1B2D",
                                padding: "5px 10px",
                                borderRadius: "10px",
                                fontWeight: "500",
                              }}
                              className={
                                activeButtonPhysical?.includes(level.value)
                                  ? "active"
                                  : ""
                              }
                              onClick={(e) =>
                                handlePhysicalDemand(e, level.value)
                              }
                            >
                              {level.label}
                            </button>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      {/* <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledSecondary(e)}
              >
                Sedentary
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledLight(e)}
              >
                Light
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledMedium(e)}
              >
                Medium
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledHeavy(e)}
              >
                Heavy
              </Button>
            </Box>

            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledVeryHeavy(e)}
              >
                Very Heavy
              </Button>
            </Box> */}
                    </Stack>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        spacing={"15px"}
                        marginTop={"10px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                          },
                        }}
                      >
                        {physicalDemandData?.map((option, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              paddingRight={"15px"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                // "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p">{option.Name}</Typography>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <FormControl>
                                  {/* <RadioGroup row sx={{ flexWrap: "nowrap" }}> */}
                                  <Stack direction={"row"}>
                                    {["N", "O", "F", "C"]?.map(
                                      (radioValue, index) => (
                                        <Fragment key={index}>
                                          {getCheckBox(
                                            radioValue,
                                            option.Name,
                                            formik
                                          )}
                                        </Fragment>
                                      )
                                    )}
                                  </Stack>
                                  {/* </RadioGroup> */}
                                </FormControl>
                              </Stack>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </TabPanel>

                  {/* <CognitiveTabs {...cognitiveProps} /> */}
                  <TabPanel value={value} index={1}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Box margin="10px 0">
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <Typography variant="p" marginRight="20px">
                            SVP
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <FormControl>
                              <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                                {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                                  (radioValue, index) => (
                                    <React.Fragment key={index}>
                                      {getCheckBoxCognitive(
                                        radioValue,
                                        "SVP",
                                        formik
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                              </RadioGroup>
                            </FormControl>
                          </Stack>
                        </Stack>
                      </Box>
                      <Grid
                        container
                        spacing={"15px"}
                        marginTop={"10px"}
                        // sx={{}}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                          },
                        }}
                      >
                        {cognitiveDemandData?.map(
                          (
                            option,
                            index // it is cognitiveDemandData
                          ) => (
                            <Grid item xs={6} key={index}>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                paddingRight={"15px"}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  // "&:hover": { backgroundColor: "#E6F3FB66" },
                                }}
                              >
                                <Typography variant="p">
                                  {option.Description}
                                </Typography>
                                <Stack
                                  direction={"row"}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <FormControl>
                                    <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                                      {["1", "2", "3", "4", "5", "6"].map(
                                        (radioValue, index) => (
                                          <Fragment key={index}>
                                            {getCheckBoxCognitive(
                                              radioValue,
                                              option.Description,
                                              formik
                                            )}
                                          </Fragment>
                                        )
                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                              </Stack>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </TabPanel>
                  {/* <EnvironmentalTabs {...environmentProps} /> */}
                  <TabPanel value={value} index={2}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        spacing={"15px"}
                        marginTop={"10px"}
                        // sx={{}}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                          },
                        }}
                      >
                        {environmentalDemandData?.map((option, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              paddingRight={"15px"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                // "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p">
                                {option.Description}
                              </Typography>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <FormControl>
                                  <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                                    {option.IsMultiLevel
                                      ? ["1", "2", "3", "4", "5"].map(
                                          (radioValue, index) => (
                                            <Fragment key={index}>
                                              {getCheckBoxEnvironmentalMultilevel(
                                                radioValue,
                                                option.Description,
                                                formik
                                              )}
                                            </Fragment>
                                          )
                                        )
                                      : ["N", "O", "F", "C"].map(
                                          (radioValue, index) => (
                                            <Fragment key={index}>
                                              {getCheckBoxEnvironmental(
                                                radioValue,
                                                option.Description,
                                                formik
                                              )}
                                            </Fragment>
                                          )
                                        )}
                                  </RadioGroup>
                                </FormControl>
                              </Stack>
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </TabPanel>
                  <Stack
                    direction={"row"}
                    spacing={"10px"}
                    marginTop={"20px"}
                    alignItems={"flex-end"}
                  >
                    <Box flexGrow={1}>
                      <TextField
                        variant="filled"
                        multiline
                        rows={1}
                        maxRows={4}
                        fullWidth
                        placeholder="Notes"
                        name="notes"
                        value={notes}
                        // onChange={formik.handleChange}
                        sx={{
                          "& .MuiInputBase-multiline": {
                            padding: "10px 12px 8px",
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<SearchIcon />}
                        type="submit"
                      >
                        Search
                      </Button>
                    </Box>
                  </Stack>
                </form>
              </Box>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </Fragment>
  );
};

export default TransferableSkillFilterModel;
