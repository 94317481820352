import React from "react";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import BackBtnIcon from "../../../assets/images/icons/BackBtn.svg";
import { Typography } from "@mui/material";

const ThemedBackButton = ({ onBack, text = "Back" }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <IconButton
      sx={{
        backgroundColor: "#E8E8EA4D",
        borderRadius: "5px",
        border: "2px solid #ffffff",
        "& img": {
          width: "15px",
          height: "12px",
        },
        "&:hover": {
          backgroundColor: "#E8E8EA66",
        },
        "& .MuiTypography-root": {
          margin: "0 4px 0 2px",
          fontSize: "14px",
          color: "#ffffff",
        },
      }}
      onClick={handleBack}
      aria-label="Go back"
    >
      <img src={BackBtnIcon} alt="Back" />

      <Typography>{text}</Typography>
    </IconButton>
  );
};

export default ThemedBackButton;
