const generateFibonacci = (n, start = [0, 1]) => {
  if (n === 1) return [start[0]];
  const fib = [start[0], start[1]];
  while (fib.length < n) {
    fib.push(fib[fib.length - 1] + fib[fib.length - 2]);
  }
  return fib;
};

function encryptNumber(input) {
  const digits = String(input).split("").map(Number);
  const fib = generateFibonacci(digits.length);
  const encrypted = digits.map((digit, index) => (digit + fib[index]) % 10);
  return encrypted.join("");
}

const decryptSocCode = (input) => {
  const digits = String(input).replaceAll("-", "").split("").map(Number);
  const fib = generateFibonacci(digits.length);
  const decrypted = digits.map((digit, index) => {
    const result = (digit - fib[index]) % 10;
    return result < 0 ? result + 10 : result; // Ensure no negative digits
  });
  return decrypted.join("");
};

export { encryptNumber, generateFibonacci, decryptSocCode };
