import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
  linearProgressClasses,
  LinearProgress,
  styled,
  CircularProgress,
} from "@mui/material";
import ProfileIcon from "../../assets/images/icons/Profile-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/Edit-Icon.svg";
import SignOutModel from "./SignOutModel";
import { useNavigate } from "react-router";
import { handleGetRequest } from "../../services/GetTemplate";
import ChangePasswordModal from "./changePasswordModal";
import ONETLogo from "../../assets/images/icons/onet-in-it.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const Account = () => {
  const [open, setOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleChangePasswordClickOpen = () => {
    setChangePasswordOpen(true);
  };
  const handleChangePasswordClose = () => {
    setChangePasswordOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true);
      const resp = await handleGetRequest("auth/current", true);
      if (resp.statusCode === 200) {
        setUserData(resp.data);
      }
      setLoading(false);
    };
    asyncFunc();
  }, []);
  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          paddingTop: "75px",
          marginTop: "2px",
          paddingBottom: 2,
          px: { xs: "10px" },
        }}
      >
        {loading ? (
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 4,
              backgroundColor: "#95959512",
              borderRadius: "10px",
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Stack className="bg-white" padding={2} borderRadius={"10px"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingBottom={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="h6" component={"h6"} fontSize={"1rem"}>
                    Account Overview
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={ProfileIcon}
                    className="SeetingProfileImage"
                    alt=""
                  />
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingY={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    First Name
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" component={"p"}>
                    {userData.firstname || "-"}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingY={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    Last Name
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" component={"p"}>
                    {userData.lastname || "-"}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingY={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    Email
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" component={"p"}>
                    {userData.email || "-"}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingY={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    Company
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" component={"p"}>
                    {userData.company || "-"}
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingY={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    Phone#
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" component={"p"}>
                    {userData.phonenumber || "-"}
                  </Typography>
                </Box>
              </Stack>
            </Stack>

            <Stack
              className="bg-white"
              padding={2}
              borderRadius={"10px"}
              mt={1}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingBottom={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    Passwords
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    className="EyeIconButton"
                    onClick={handleChangePasswordClickOpen}
                  >
                    <SvgIcon component={EditIcon} inheritViewBox />
                  </IconButton>
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingY={"10px"}
                borderBottom={"1px solid #B8B8BE"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    License
                  </Typography>
                </Box>
              </Stack>
            </Stack>

            <Stack
              className="bg-white"
              padding={2}
              borderRadius={"10px"}
              mt={1}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingBottom={"10px"}
              >
                <Box>
                  <Typography variant="p" component={"p"} fontWeight={"bold"}>
                    My Subscriptions
                  </Typography>
                </Box>
                {userData.subscription && (
                  <Box>
                    <Button variant="outlined">{userData.subscription}</Button>
                  </Box>
                )}
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingBottom={"10px"}
              >
                <Box>
                  <Typography
                    variant="p"
                    component={"p"}
                    color={"primary.main"}
                  >
                    75%
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="p" component={"p"}>
                    Subscription End in 25 Days
                  </Typography>
                </Box>
              </Stack>
              <Box paddingBottom={"10px"} borderBottom={"1px solid #B8B8BE"}>
                <BorderLinearProgress variant="determinate" value={75} />
              </Box>
              <Box mt={1}>
                <Typography
                  variant="p"
                  component={"p"}
                  color={"primary.main"}
                  sx={{
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => navigate("/subsciptionhistory")}
                >
                  View Subscription History
                </Typography>
              </Box>
            </Stack>
            <Box mt={1}>
              <Button variant="contained" fullWidth onClick={handleClickOpen}>
                Sign Out
              </Button>
            </Box>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              margin="5rem 0"
            >
              <img src={ONETLogo} alt="O*NET" />
            </Stack>
          </>
        )}
      </Container>
      <SignOutModel open={open} handleClose={handleClose} />
      <ChangePasswordModal
        open={changePasswordOpen}
        handleClose={handleChangePasswordClose}
      />
    </>
  );
};

export default Account;
