import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
  ToggleButton,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/right-arrow-icon-Large.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatString } from "../HypotheticalAnalyzer/utils";
import { CodeTab } from "../HypotheticalAnalyzer/helperComponents/codeTab";
import GetDefination from "../../utils/getDefination";
import RequirementsTab from "../HypotheticalAnalyzer/helperComponents/requirementTab";
import { decryptSocCode } from "../../utils/decryptSocCode";
import { handleGetEmploymentEstimateRequest } from "../../services/getEmployementEstimate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SOCGroupInfo = () => {
  const [DOTTitleViewDisplay, setDOTTitleViewDisplay] = useState(false);
  const [tableDatabyDotCode, setTableDatabyDotCode] = useState();
  // State to track selected state of each item
  const [selectedItems, setSelectedItems] = useState({});
  const [soc2918Definations, setSoc2918Definations] = useState({});
  const [filteredDotForSoc, setFilteredDotForSoc] = useState();
  const [PhysicalDemandData, setPhysicalDemandData] = useState();
  const [enviromentalData, setEnviromentalData] = useState();
  const [DotData, setDotData] = useState();
  const [aptitudeData, setAptitudeData] = useState();
  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();
  const [employEstimate, setEmployEstimate] = useState({});
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState([]);

  const navigate = useNavigate();

  var dbName = "veToolkitDB";

  let rowData = localStorage.getItem("row");
  if (rowData) rowData = JSON.parse(rowData);

  const location = useLocation();
  const { showOrsFilters = false } = location.state || {};

  // console.log("SOCGroupInfo rowData ==>", rowData);

  var dbCurrentVersion;

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;
      // console.log("Current IndexedDB version:", currentVersion);

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  var PhysicalDemandsData = JSON.parse(localStorage.getItem("PhysicalDemands"));
  var EnvironmentalDemandsData = JSON.parse(
    localStorage.getItem("EnvironmentalDemands")
  );
  var EnvironmentalDemandLevelData = JSON.parse(
    localStorage.getItem("EnvironmentalDemandLevel")
  );
  var DotGedsData = JSON.parse(localStorage.getItem("DotGeds"));

  var DotGedsLevelsData = JSON.parse(localStorage.getItem("DotGedsLevels"));
  var AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      // console.log("going to fetch OewsNationalM2022");

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      // console.log("fetched OewsNationalM2022");
      const objectStore = transaction.objectStore("OewsNationalM2022");
      // console.log("store object created objectStore");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const idToMatch = rowData["2018SocCode"];
        const filteredOews = getData.result?.find(
          (value) => value.OccCode === idToMatch
        );
        // console.log("OewsNational ===> ", filteredOews);
        setOewsNationalM2022Data(filteredOews);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  const getEmployementEstimate = async (dotCodes) => {
    const data = await handleGetEmploymentEstimateRequest(
      JSON.stringify(dotCodes).replaceAll("[", "").replace("]", "")
    );
    setEmployEstimate(data?.data?.data ?? []);
  };

  const handleSearchDotCode = (dotcodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      // console.log("store: ", store.indexNames);

      const index = store.index("DotCode");
      // console.log("index", index.DotCode);

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          var dotCodeMatch = String(cursor.value.DotCode);

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (dotCodeMatch.includes(dotcodeFind)) {
            partialMatchResults.push(cursor.value);
          }
          cursor.continue();
        } else {
          // console.log("No more entries!");
          // console.log("Matching records: on dotcode", partialMatchResults);
          // setResultRows([]);

          setTableDatabyDotCode(partialMatchResults[0]);
          matchedRecordsPhysical(partialMatchResults);
          matchedRecordsEnvironmental(partialMatchResults);
          matchedRecordsGEDs(partialMatchResults);
          matchedRecordsAptitudes(partialMatchResults);
          matchedRecordsCodes(partialMatchResults);
          partialMatchResults = [];
        }
      };
    };
  };

  const matchedRecordsPhysical = (partialMatchResults) => {
    var resultPhys = [];
    for (var i = 0; i < PhysicalDemandsData.length; i++) {
      var CombinePhysSlug = `Phys${+(i + 1)}`;
      var PhysDemandValue = partialMatchResults[0][CombinePhysSlug];
      var outputPhys = {
        name: PhysicalDemandsData[i]?.Name,
        value: PhysDemandValue,
      };
      resultPhys.push(outputPhys);
    }
    setPhysicalDemandData(resultPhys);
    // console.log("resultsss", result)
  };

  const matchedRecordsEnvironmental = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < EnvironmentalDemandsData.length; i++) {
      if (EnvironmentalDemandsData[i].IsMultiLevel) {
        var CombineEnvSlug = `Env${i + 1}`;
        var envDemandValue = partialMatchResults[0][CombineEnvSlug] - 1;

        var envDemandLevels = EnvironmentalDemandLevelData.filter(
          (row) => row.EnvironmentalDemandId === EnvironmentalDemandsData[i].Id
        );
        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: envDemandLevels[envDemandValue]?.Description,
        };
        result.push(output);
      } else {
        var CombineEnvSlug = `Env${+(i + 1)}`;
        var demandValue = partialMatchResults[0][CombineEnvSlug];

        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: demandValue,
        };
        result.push(output);
      }
    }
    setEnviromentalData(result);
    // console.log("resultsssresultsss", result);
  };

  const matchedRecordsGEDs = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < DotGedsData.length; i++) {
      if (i == 0) {
        // GedG mean reasoning
        var reasoningLevel = partialMatchResults[0].GedG;
        var outputGEDs = {
          name: DotGedsData[0]?.Name,
          value: reasoningLevel,
        };
        result.push(outputGEDs);
      } else if (i == 1) {
        // GedE mean math
        var mathLevel = partialMatchResults[0].GedE;
        var outputGEDs = {
          name: DotGedsData[1]?.Name,
          value: mathLevel,
        };
        result.push(outputGEDs);
      } else if (i == 2) {
        // GedD mean Language
        var languageLevel = partialMatchResults[0].GedD;
        var outputGEDs = {
          name: DotGedsData[2]?.Name,
          value: languageLevel,
        };
        result.push(outputGEDs);
      } else {
        break;
      }
    }
    setDotData(result);
    // console.log("resultsssresultsss", result);
  };

  const matchedRecordsAptitudes = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < AptitudesData.length; i++) {
      var demandAptChar = AptitudesData[i].ShortCharacter;
      var demandAptDesc = AptitudesData[i].Description;
      var combinedSlugApt = `Apt${demandAptChar}`;
      var demndValueApt = partialMatchResults[0][combinedSlugApt];
      var OapCode = partialMatchResults[0].OapCode;

      var outputAptitude = {
        description: demandAptDesc,
        value: demndValueApt,
        OapCode,
      };
      result.push(outputAptitude);
    }
    setAptitudeData(result);
    // console.log("resultsssresultsss", result);
  };

  const matchedRecordsCodes = (partialMatchResults) => {
    const [socCode, goeCode, oapCode] = [
      partialMatchResults[0].SocCode,
      partialMatchResults[0].GoeCode,
      partialMatchResults[0].OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            // console.log(`No more entries in ${storeName}!`);
            // console.log(`${storeName} records:`, resultArray);
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };

  const handleSearchDotCodeAgainstSocCode = (socCodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      // console.log("store: ", store.indexNames);

      const index = store.index("DotCode");
      // console.log("index", index.DotCode);

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          var socCodeMatch = decryptSocCode(
            String(cursor.value["2018SocCode"])
          );

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (socCodeMatch === socCodeFind.replace(/-/g, "")) {
            partialMatchResults.push(cursor.value);
          }
          cursor.continue();
        } else {
          setFilteredDotForSoc(partialMatchResults);
          const dotCodes = partialMatchResults.map((result) => result.DotCode);
          getEmployementEstimate(dotCodes);
        }
      };
    };
  };

  useEffect(() => {
    handleSearchDotCodeAgainstSocCode(rowData["2018SocCode"]);
  }, []);

  // Function to toggle select/deselect and call respective functions
  const handleToggleSelect = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = !prevSelectedItems[itemId];
      // Call handleSelect or handleDeselect based on the new state
      handleSearchDotCode(itemId); // Added by Faisal
      if (isSelected) {
        handleSelect(itemId);
        // Deselect all other items
        return { [itemId]: true };
      } else {
        handleDeselect(itemId);
        return { [itemId]: false };
      }
    });
  };

  // Define what happens when an item is selected
  const handleSelect = (itemId) => {
    setDOTTitleViewDisplay(true);
    //    setWorkHistoryHeight(window.innerHeight-500)
  };

  useEffect(() => {
    fetch("Soc2918Definations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const foundObject = data.find(
        (obj) => obj.SocCode === rowData["2018SocCode"]
      );
      setSoc2918Definations(foundObject);
      return;
    });
  }, []); // eslint-disable-line

  const handleDeselect = (itemId) => {
    // Additional deselect logic here
    //    setWorkHistoryHeight(window.innerHeight - 225);
    setDOTTitleViewDisplay(false);
  };

  const isAnyItemSelected = Object.values(selectedItems).some((value) => value);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const redirectTo = (link) => {
    navigate(link, { state: { showOrsFilters } });
  };

  const showEmployeeEstimate = (row) => {
    const result = Object.keys(employEstimate)
      .filter((key) => key !== "resultCount") // Exclude 'total'
      .find((key) => {
        return employEstimate[key].dot == row.DotCode;
      });
      if (result) {
        return employEstimate[result].dotEstEmp
      }

    return "-";
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={6}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={0}
              >
                <Box>
                  <Typography variant="h6" component={"h6"}>
                    {soc2918Definations
                      ? soc2918Definations.SocTitle
                      : "SOC Title"}
                  </Typography>
                </Box>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={1}
                >
                  {showOrsFilters && (
                    <Box>
                      <Button
                        variant="contained"
                        size="small"
                        color="greyPrimary"
                        onClick={() =>
                          redirectTo("/SOCGroupORSFilterTransferableSkill")
                        }
                      >
                        ORS Filter
                      </Button>
                    </Box>
                  )}
                  <Box>
                    <Button variant="contained" size="small" color="primary">
                      Info
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      color="greyPrimary"
                      onClick={() => redirectTo("/SOCGroupRawData")}
                    >
                      ORS Data
                    </Button>
                  </Box>
                </Stack>
              </Stack>
              <Box>
                <Stack padding={1} sx={{ borderBottom: "1px solid #666672" }}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Description
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      {formatString(rowData?.DescriptionCombined || "-")}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack direction={"row"} padding={1}>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.TotalEmp?.toLocaleString(
                        "en-US"
                      ) || "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Group Employment
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.H_Mean?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Hourly Mean Wage
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.A_Mean?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Annual Mean Wage
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              {/* <Box sx={{ borderBottom: "1px solid #666672" }}>
                <Stack padding={1}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Hypothetical Employment
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      {oewsNationalM2022Data?.TotalEmp?.toLocaleString("en-US")}
                    </Typography>
                  </Box>
                </Stack>
              </Box> */}
            </Stack>
            {DOTTitleViewDisplay && (
              <Box
                className="bg-white"
                padding={1}
                borderRadius={"10px"}
                marginTop={"10px"}
                position={"relative"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">DOT Title View</Typography>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="none"
                      sx={{
                        "& .MuiTabs-flexContainer": {
                          justifyContent: "flex-end",
                          alignItems: "center",
                          gap: 2,
                        },
                      }}
                    >
                      <Tab
                        label="Description"
                        icon={<DescriptionIcon />}
                        iconPosition="start"
                        {...a11yProps(0)}
                        sx={{
                          color:
                            value === 0
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 0 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Requirements"
                        {...a11yProps(1)}
                        icon={<RequirementsIcon />}
                        iconPosition="start"
                        sx={{
                          color:
                            value === 1
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 1 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Codes"
                        icon={<CodeIcon />}
                        iconPosition="start"
                        {...a11yProps(2)}
                        sx={{
                          color:
                            value === 2
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 2 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <a
                        href={`/dotDetail?dotCode=${tableDatabyDotCode?.DotCodeFormated}`}
                        target="_blank"
                      >
                        <button
                          style={{
                            backgroundColor: "#F5F5F5",
                            minHeight: "20px",
                            padding: "10px 8px",
                            display: "flex",
                            alignItems: "end",
                            borderRadius: 10,
                            color: "#666672",
                            cursor: "alias",
                          }}
                        >
                          Link
                        </button>
                      </a>
                    </Tabs>
                  </Box>
                </Stack>

                <TabPanel value={value} index={0}>
                  <Box>
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          {tableDatabyDotCode?.Title}
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          {tableDatabyDotCode?.DotCodeFormated}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      marginBottom={1}
                    >
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        {formatString(
                          tableDatabyDotCode?.DescriptionCombined || "-"
                        )}
                      </Typography>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <RequirementsTab
                    rowData={tableDatabyDotCode}
                    DotData={DotData}
                    PhysicalDemandData={PhysicalDemandData}
                    dbCurrentVersion={dbCurrentVersion}
                    enviromentalData={enviromentalData}
                    tableDatabyDotCode={tableDatabyDotCode}
                    aptitudeData={aptitudeData}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <Stack direction={"row"} spacing={1}>
                      <Stack flexGrow={1}>
                        <TableContainer
                          component={Paper}
                          sx={{
                            padding: 0,
                            // paddingRight: "0px !important",
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #666672",
                          }}
                        >
                          <Table stickyHeader>
                            <TableBody>
                              <CodeTab
                                {...{
                                  tableDatabyDotCode,
                                  rowData: tableDatabyDotCode,
                                }}
                              />
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Stack>
                  </Box>
                </TabPanel>
              </Box>
            )}
          </Grid>

          <Grid item xs={6}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Box>
                <Typography variant="h6" component={"h6"}>
                  {soc2918Definations
                    ? soc2918Definations.SocTitle
                    : "SOC Title"}{" "}
                  in SOC {rowData["2018SocCode"]} {rowData["Title"]}
                </Typography>
              </Box>
              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  boxShadow: "none",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">DOT</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">SVP</TableCell>
                      <TableCell align="left">Strength</TableCell>
                      <TableCell align="left">Employment Estimate</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDotForSoc?.map((row, index) => (
                      <TableRow
                        key={row.DotCodeFormated}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "& td, & th": { paddingY: "4px !important" },
                          "&:hover": { backgroundColor: "#E6F3FB66" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <span className="whitespace-nowrap">
                            {row.DotCodeFormated}
                          </span>
                        </TableCell>
                        <TableCell align="left">{row.Title}</TableCell>
                        <TableCell align="left">{row.SVP}</TableCell>
                        <TableCell align="left">{row.Strength}</TableCell>
                        <TableCell align="left">
                          {showEmployeeEstimate(row)}
                        </TableCell>
                        <TableCell align="right">
                          <ToggleButton
                            size="small"
                            value="check"
                            className={`${
                              selectedItems[row.DotCode]
                                ? "EyeIconOffButton"
                                : "EyeIconButton"
                            }`}
                            sx={{ border: 0 }}
                            selected={!!selectedItems[row.DotCode]} // Use double negation to ensure boolean
                            onChange={() => handleToggleSelect(row.DotCode)}
                            // disabled={
                            //   isAnyItemSelected && !selectedItems[row.DOT]
                            // }
                          >
                            {selectedItems[row.DotCode] ? (
                              <SvgIcon component={EyeIconOff} inheritViewBox />
                            ) : (
                              <SvgIcon component={EyeIcon} inheritViewBox />
                            )}
                          </ToggleButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SOCGroupInfo;
