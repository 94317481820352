import React from "react";
import { TableCell, Box, IconButton } from "@mui/material";
import { ReactComponent as SortIcon } from "../../../assets/images/icons/sort-from-bottom-to-top.svg";
const CustomTableCell = ({
  align = "left",
  sortResultsKey,
  sortOrder,
  currentSortKey,
  onSort,
  label,
  sortKey,
}) => {
  const isSorted = currentSortKey === sortResultsKey;

  return (
    <TableCell align={align}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={align}
        onClick={() => onSort(sortKey)} 
        sx={{
          cursor: "pointer",
        }}
        className="result-header"
      >
        {label}
        <Box
          component="span"
          sx={{
            transform:
              isSorted && sortOrder !== "asc" ? "rotate(180deg)" : "none",
            transition: "transform 0.1s",
          }}
        >
          {isSorted && (
            <IconButton
              size="small"
              sx={{
                padding: 0,
                color: "inherit",
              }}
            >
              <SortIcon
                height={16}
                width={16}
                className={`${
                  currentSortKey === sortResultsKey ? "block" : "hidden"
                }`}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    </TableCell>
  );
};

export default CustomTableCell;
