import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
// import { trimData } from "../utilities/TrimPayloadData";
import { loadingAction } from "../redux/actions/loadingAction";

export const handlePostRequest =
  (data, url, isShowLoad = false, isShowToast = true) =>
  async (dispatch) => {
    // data = await trimData(data);
    try {
      if (isShowLoad) dispatch(loadingAction(true));
      const response = await axios({
        method: "post",
        url: `${baseURL + url}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE1LCJpYXQiOjE3MTU3OTU4MTksImV4cCI6MTcxODM4NzgxOX0.XIADMeQYE3KwKe0-l300PDuU_Gg3J2EMccCT4T43Ya8`,
        },
      });
      // if (isShowToast)
      //   toast.success(response?.data?.message, {
      //     autoClose: 2000,
      //   });
      if (isShowLoad) dispatch(loadingAction(false));
      return response?.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("password");
      }
      if (isShowLoad) dispatch(loadingAction(false));
      if (error?.response?.status === 400 || error?.response?.status === 500) {
        toast.warn(
          error?.response?.data?.messages ||
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Something went wrong !!",
          {
            autoClose: 2000,
          }
        );
      } else {
        toast.warn(
          error?.response?.data?.messages ||
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Something went wrong !!",
          {
            autoClose: 2000,
          }
        );
      }
    }
  };
