import { Tooltip } from "@mui/material";
import { useState } from "react";
import { CustomTooltip } from "../components/custom";

const GetDefination = ({
  dbCurrentVersion,
  TypeName,
  Description,
  Code,
  children,
}) => {
  var dbName = "veToolkitDB";
  const [tooltip, setTooltip] = useState("");

  async function fetchLongDefinations({ TypeName, Description, Code }) {
    var data = "";
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };
    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["LongDefinations"], "readonly");
      const objectStore = transaction.objectStore("LongDefinations");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        let data = "";
        if (getData.result) {
          if (TypeName === "Aptitudes") {
            data = getData.result.find((item) => {
              return (
                item.TypeName === TypeName && item.Description === Description
              );
            });
          } else {
            data = getData.result.find((item) => {
              return (
                item.Code === Code &&
                item.TypeName === TypeName &&
                item.Description === Description
              );
            });
          }
          setTooltip(data ? data.LongDefination : "No defination found");
        }
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
      return data;
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", { autoIncrement: true });
    };
  }

  function fetchLongDefinationPhysicalEnvironment({ TypeName, Description }) {
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(
        ["LongDefinationPhysicalEnvironment"],
        "readonly"
      );
      const objectStore = transaction.objectStore(
        "LongDefinationPhysicalEnvironment"
      );

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        let data = "";
        if (getData.result) {
          data = getData.result.find(
            (item) =>
              item.TypeName === TypeName && item.Description === Description
          );

          setTooltip(data ? data.LongDefinition : "No defination found");
        }
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", { autoIncrement: true });
    };
  }

  async function findDefination({ TypeName, Description, Code }) { 
    if (TypeName === "GED") {
      await fetchLongDefinations({
        TypeName,
        Description,
        Code,
      });
    } else if (TypeName === "Physical Demands") {
      await fetchLongDefinationPhysicalEnvironment({
        TypeName,
        Description,
        Code,
      });
    } else if (TypeName === "Environmental") {
      await fetchLongDefinationPhysicalEnvironment({
        TypeName,
        Description,
        Code,
      });
    } else if (TypeName === "Temperaments") {
      await fetchLongDefinations({
        TypeName,
        Description,
        Code,
      });
    } else if (TypeName === "Aptitudes") {
      await fetchLongDefinations({
        TypeName,
        Description,
        Code,
      });
    }
  }

  return (
    <CustomTooltip title={tooltip} placement="right">
      {/* <Tooltip title={tooltip} placement="right"> */}
      <div
        onMouseEnter={() =>
          findDefination({
            TypeName,
            Description,
            Code,
          })
        }
        className="w-fit-content"
      >
        {children}
      </div>
      {/* </Tooltip> */}
    </CustomTooltip>
  );
};

export default GetDefination;
