import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ReactComponent as DescriptionIcon } from "../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../assets/images/icons/code-lg-icon.svg";
import { ReactComponent as AddIcon } from "../assets/images/icons/add-icon.svg";
import { postWorkHistory } from "../services/postWorkHistory";

import React, { useEffect, useState } from "react";
import { formatString, reverseString } from "./HypotheticalAnalyzer/utils";
import { handleGetRequest } from "../services/GetTemplate";
import GetDefination from "../utils/getDefination";
import {
  addCommaAfterThree,
  splitIntoTwo,
} from "./HypotheticalAnalyzer/helperComponents/codeTab";
import RequirementsTab from "./HypotheticalAnalyzer/helperComponents/requirementTab";
import { decryptSocCode } from "../utils/decryptSocCode";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function DotDetails() {
  const [value, setValue] = useState(0);
  const [DotData, setDotData] = useState();
  const [PhysicalDemandData, setPhysicalDemandData] = useState();
  const [tableDatabyDotCode, setTableDatabyDotCode] = useState({});
  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();
  const [enviromentalData, setEnviromentalData] = useState();
  const [aptitudeData, setAptitudeData] = useState();
  const [loadingAddData, setLoadingAddData] = useState(false);
  const [resultRows, setResultRows] = useState([]);
  const [selectedNetSoc, setSelectedNetSoc] = useState({});
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState({});
  const [goeDefinations, setGoeDefinations] = useState([]);
  const [naicDefinations, setNaicDefinations] = useState([]);
  const [codeDefinations, setCodeDefinations] = useState([]);
  const [socOnetDefinitions, setSocOnetDefinitions] = useState([]);

  const profileId = localStorage.getItem("profileId");

  var dbName = "veToolkitDB";
  var dbCurrentVersion = 8;

  var PhysicalDemandsData = JSON.parse(localStorage.getItem("PhysicalDemands"));
  var EnvironmentalDemandsData = JSON.parse(
    localStorage.getItem("EnvironmentalDemands")
  );
  var EnvironmentalDemandLevelData = JSON.parse(
    localStorage.getItem("EnvironmentalDemandLevel")
  );
  var DotGedsData = JSON.parse(localStorage.getItem("DotGeds"));

  var DotGedsLevelsData = JSON.parse(localStorage.getItem("DotGedsLevels"));
  var AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));

  useEffect(() => {
    getWorkHistoryData();
  }, []); // eslint-disable-line

  const onSearchNaice = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const result = getData.result.find(
          (row) => row.OccCode.replace("-", "") == item["2018SocCode"]
        );
        setOewsNationalM2022Data(
          result ?? {
            OccCode:
              item["2018SocCode"].slice(0, 2) +
              "-" +
              item["2018SocCode"].slice(2),
          }
        );
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  };

  const getSocCode = () => {
    return oewsNationalM2022Data.OccCode;
  };

  const getSocTitle = () => {
    return oewsNationalM2022Data.OccTitle;
  };

  const onSearchNet = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OnnetSocConversions"], "readonly");
      const objectStore = transaction.objectStore("OnnetSocConversions");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const result = getData.result.find(
          (row) => row.DotCode === item.DotCode
        );
        setSelectedNetSoc(result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OnnetSocConversions", { autoIncrement: true });
    };
  };

  const getWorkHistoryData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const dotCode = queryParams.get("dotCode");
    handleSearchDotCode(dotCode);
  };

  const matchedRecordsPhysical = (partialMatchResults) => {
    var resultPhys = [];
    for (var i = 0; i < PhysicalDemandsData.length; i++) {
      var CombinePhysSlug = `Phys${+(i + 1)}`;
      var PhysDemandValue = partialMatchResults[0][CombinePhysSlug];
      var outputPhys = {
        name: PhysicalDemandsData[i]?.Name,
        value: PhysDemandValue,
      };
      resultPhys.push(outputPhys);
    }
    setPhysicalDemandData(resultPhys);
    // console.log("resultsss", result)
  };

  const handledAddData = async (row, index = 0) => {
    // setRowNumber(index);
    setLoadingAddData(true);
    const data = {
      profileId: profileId,
      dotcode: row.DotCodeFormated?.toString(),
      title: row.Title,
      svp: row.SVP?.toString(),
      physical: row.Strength,
      description: row.DescriptionCombined,
    };

    const response = await postWorkHistory(data);

    if (response?.statusCode === 200) {
      resultRows?.splice(row, 1);
      getWorkHistoryData();
      setLoadingAddData(false);
    } else {
      setLoadingAddData(false);
    }
  };

  const handleSearchDotCode = (dotcodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      // console.log("store: ", store.indexNames);

      const index = store.index("DotCode");
      // console.log("index", index.DotCode);

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          const value = cursor.value;
          let dotCodeMatch = String(value.DotCode);
          let dotCodeFormattedMatch = String(value.DotCodeFormated);
          value["2018SocCode"] = decryptSocCode(value["2018SocCode"]);

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (dotCodeMatch.includes(dotcodeFind)) {
            partialMatchResults.push(value);
          }
          if (dotCodeFormattedMatch.includes(dotcodeFind)) {
            partialMatchResults.push(value);
          }
          cursor.continue();
        } else {
          // console.log("No more entries!");
          // console.log("Matching records: on dotcode", partialMatchResults);
          // setResultRows([]);
          document.title = partialMatchResults[0].Title;
          setTableDatabyDotCode(partialMatchResults[0]);
          matchedRecordsPhysical(partialMatchResults);
          matchedRecordsEnvironmental(partialMatchResults);
          matchedRecordsGEDs(partialMatchResults);
          matchedRecordsAptitudes(partialMatchResults);
          matchedRecordsCodes(partialMatchResults);
          onSearchNet(partialMatchResults[0]);
          onSearchNaice(partialMatchResults[0]);
          partialMatchResults = [];
        }
      };
    };
  };

  const matchedRecordsCodes = (partialMatchResults) => {
    const [socCode, goeCode, oapCode] = [
      partialMatchResults[0].SocCode,
      partialMatchResults[0].GoeCode,
      partialMatchResults[0].OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            // console.log(`No more entries in ${storeName}!`);
            // console.log(`${storeName} records:`, resultArray);
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };

  const matchedRecordsEnvironmental = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < EnvironmentalDemandsData.length; i++) {
      if (EnvironmentalDemandsData[i].IsMultiLevel) {
        var CombineEnvSlug = `Env${i + 1}`;
        var envDemandValue = partialMatchResults[0][CombineEnvSlug] - 1;

        var envDemandLevels = EnvironmentalDemandLevelData.filter(
          (row) => row.EnvironmentalDemandId === EnvironmentalDemandsData[i].Id
        );
        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: envDemandLevels[envDemandValue]?.Description,
        };
        result.push(output);
      } else {
        var CombineEnvSlug = `Env${+(i + 1)}`;
        var demandValue = partialMatchResults[0][CombineEnvSlug];

        var output = {
          name: EnvironmentalDemandsData[i]?.Description,
          value: demandValue,
        };
        result.push(output);
      }
    }
    setEnviromentalData(result);
    // console.log("resultsssresultsss", result);
  };

  const matchedRecordsGEDs = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < DotGedsData.length; i++) {
      if (i == 0) {
        // GedG mean reasoning
        var reasoningLevel = partialMatchResults[0].GedG;
        var outputGEDs = {
          name: DotGedsData[0]?.Name,
          value: reasoningLevel,
        };
        result.push(outputGEDs);
      } else if (i == 1) {
        // GedE mean math
        var mathLevel = partialMatchResults[0].GedE;
        var outputGEDs = {
          name: DotGedsData[1]?.Name,
          value: mathLevel,
        };
        result.push(outputGEDs);
      } else if (i == 2) {
        // GedD mean Language
        var languageLevel = partialMatchResults[0].GedD;
        var outputGEDs = {
          name: DotGedsData[2]?.Name,
          value: languageLevel,
        };
        result.push(outputGEDs);
      } else {
        break;
      }
    }
    setDotData(result);
    // console.log("resultsssresultsss", result);
  };

  const matchedRecordsAptitudes = (partialMatchResults) => {
    var result = [];
    for (var i = 0; i < AptitudesData.length; i++) {
      var demandAptChar = AptitudesData[i].ShortCharacter;
      var demandAptDesc = AptitudesData[i].Description;
      var combinedSlugApt = `Apt${demandAptChar}`;
      var demndValueApt = partialMatchResults[0][combinedSlugApt];

      var outputAptitude = {
        description: demandAptDesc,
        value: demndValueApt,
      };
      result.push(outputAptitude);
    }
    setAptitudeData(result);
    // console.log("resultsssresultsss", result);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetch("GoeDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setGoeDefinations(data);
      return;
    });
    fetch("socOnetDefinitions.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSocOnetDefinitions(data);
      return;
    });
    fetch("LongDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCodeDefinations(data);
      return;
    });
    fetch("niacDefinitionsV2.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNaicDefinations(data);
      return;
    });
  }, []);

  const getGoeTitle = (goeCode) => {
    const formattedGoeCode = "0" + goeCode;
    const filteredGoe = goeDefinations?.find((goe) => {
      return goe.SocCode === formattedGoeCode;
    });
    return filteredGoe ? filteredGoe.GoeTitle : "-";
  };
  const getOnetTitle = (OnnetCode) => {
    const formattedGoeCode = OnnetCode;
    const filteredOnet = socOnetDefinitions?.find((onet) => {
      return onet.ONET_SOC_C_8 === formattedGoeCode;
    });
    return filteredOnet ? filteredOnet.LayTitle_c_128 : "-";
  };

  return (
    <Box
      className="bg-white !mt-20"
      padding={1}
      borderRadius={"10px"}
      marginTop={"10px"}
      height={"auto"}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Typography variant="h6">DOT Title View</Typography>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="none"
            sx={{
              "& .MuiTabs-flexContainer": {
                justifyContent: "flex-end",
                gap: 2,
              },
            }}
          >
            <Tab
              label="Description"
              icon={<DescriptionIcon />}
              iconPosition="start"
              {...a11yProps(0)}
              sx={{
                color:
                  value === 0 ? "#ffffff !important" : "#666672 !important",
                backgroundColor: value === 0 ? "#0089D7" : "#F5F5F5",
                minHeight: "20px",
                paddingX: "10px",
                paddingY: "8px",
                alignItems: "end",
              }}
            />
            <Tab
              label="Requirements"
              {...a11yProps(1)}
              icon={<RequirementsIcon />}
              iconPosition="start"
              sx={{
                color:
                  value === 1 ? "#ffffff !important" : "#666672 !important",
                backgroundColor: value === 1 ? "#0089D7" : "#F5F5F5",
                minHeight: "20px",
                paddingX: "10px",
                paddingY: "8px",
                alignItems: "end",
              }}
            />
            <Tab
              label="Codes"
              icon={<CodeIcon />}
              iconPosition="start"
              {...a11yProps(2)}
              sx={{
                color:
                  value === 2 ? "#ffffff !important" : "#666672 !important",
                backgroundColor: value === 2 ? "#0089D7" : "#F5F5F5",
                minHeight: "20px",
                paddingX: "10px",
                paddingY: "8px",
                alignItems: "end",
              }}
            />
          </Tabs>
        </Box>
      </Stack>

      <TabPanel value={value} index={0}>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
            <Box flexGrow={1}>
              <Typography variant="p" component={"p"} color={"primary"}>
                {tableDatabyDotCode?.Title || tableDatabyDotCode?.title}
              </Typography>
            </Box>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography
                variant="p"
                component={"p"}
                color={"text.grey"}
                sx={{ display: "flex" }}
              >
                <CodeIcon />
                Code
              </Typography>
              <Typography variant="h6" component={"h6"} fontSize={"0.875rem"}>
                {tableDatabyDotCode?.DotCodeFormated ||
                  tableDatabyDotCode?.DotCode}
              </Typography>
            </Stack>
          </Stack>

          <Typography
            variant="p"
            component={"p"}
            color={"#484957"}
            paddingBottom={"10px"}
          >
            {formatString(tableDatabyDotCode?.DescriptionCombined)}
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RequirementsTab
          rowData={tableDatabyDotCode}
          DotData={DotData}
          PhysicalDemandData={PhysicalDemandData}
          dbCurrentVersion={dbCurrentVersion}
          enviromentalData={enviromentalData}
          tableDatabyDotCode={tableDatabyDotCode}
          aptitudeData={aptitudeData}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
          <Box flexGrow={1}>
            <Typography variant="p" component={"p"} color={"primary"}>
              {tableDatabyDotCode?.Title || tableDatabyDotCode?.title}
            </Typography>
          </Box>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography
              variant="p"
              component={"p"}
              color={"text.grey"}
              sx={{ display: "flex" }}
            >
              <CodeIcon />
              Code
            </Typography>
            <Typography variant="h6" component={"h6"} fontSize={"0.875rem"}>
              {tableDatabyDotCode?.DotCodeFormated ||
                tableDatabyDotCode?.DotCode}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"20%"}
          >
            Type
          </Typography>

          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"20%"}
          >
            Code
          </Typography>
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"20%"}
          >
            Definitions
          </Typography>
          {/* <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Description
          </Typography> */}
        </Stack>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            borderRadius={"10px"}
            backgroundColor={"#F5F5F5"}
            marginBottom={"5px"}
          >
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                MPSMS
              </Typography>
              <Stack width={"80%"} direction={"column"} gap={"20px"}>
                {codeDefinations?.map((item, index) => {
                  return (
                    item?.Code === tableDatabyDotCode?.MPSMS &&
                    (() => {
                      const isItemPresent = item.LongDefination !== "NULL";
                      return isItemPresent ? (
                        <Stack direction={"row"}>
                          <Typography variant="p" component={"p"} width={"25%"}>
                            {item?.Code}
                          </Typography>
                          <Typography variant="p" component={"p"} width={"75%"}>
                            {item?.LongDefination}
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      );
                    })()
                  );
                })}
              </Stack>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                GOE
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {tableDatabyDotCode?.GoeCode &&
                  `${reverseString(
                    tableDatabyDotCode.GoeCode.toString().slice(0, 2)
                  )}.${reverseString(
                    tableDatabyDotCode.GoeCode.toString().slice(2, 4)
                  )}.${reverseString(
                    tableDatabyDotCode.GoeCode.toString().slice(4, 6)
                  )}`}
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {getGoeTitle(tableDatabyDotCode?.GoeCode).split(":")[1]?.trim()}
              </Typography>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                RIASEC
              </Typography>
              {/* <Typography variant="p" component={"p"} width={"20%"}>
                {tableDatabyDotCode?.RIASEC}
              </Typography>
              <Typography
                className="flex flex-col gap-5"
                variant="p"
                component={"p"}
                width={"60%"}
              >
                {codeDefinations?.map((item, index) => {
                  return item?.Code === tableDatabyDotCode?.RIASEC ? (
                    <span key={index}>{item?.LongDefination}</span>
                  ) : (
                    <></>
                  );
                })}
              </Typography> */}
              <Box width={"80%"}>
                            {codeDefinations?.map((item, index) => {
                              // Convert RIASEC into an array
                              const riasecArray =
                                tableDatabyDotCode?.RIASEC?.split("") || [];
                              return (
                                riasecArray.includes(item?.Code) &&
                                item?.TypeName === "RIASEC" && (
                                  <Stack direction={"row"} mb={"5px"} >
                                    <Typography
                                      width={"25%"}
                                      key={index}
                                      variant="p"
                                      component={"p"}
                                      style={{ display: "block" }}
                                    >
                                      {item.Code}
                                    </Typography>

                                    <Typography
                                      className="flex flex-col gap-2"
                                      variant="p"
                                      component={"p"}
                                      width={"75%"}
                                    >
                                      <span key={index}>
                                        {item?.LongDefination}
                                      </span>
                                    </Typography>
                                  </Stack>
                                )
                              );
                            })}
                          </Box>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                Work Fields
              </Typography>
              <Stack width={"80%"} direction={"column"} gap={"20px"}>
                {codeDefinations?.map((item, index) => {
                  return (
                    item.TypeName === "Work Fields" &&
                    (() => {
                      const isItemPresent = splitIntoTwo(
                        tableDatabyDotCode?.WorkFields
                      )?.includes(item?.Code);
                      return isItemPresent ? (
                        <Stack direction={"row"}>
                          <Typography variant="p" component={"p"} width={"25%"}>
                            {item?.Code}
                          </Typography>
                          <Typography variant="p" component={"p"} width={"75%"}>
                            {item?.LongDefination}
                          </Typography>
                        </Stack>
                      ) : (
                        <></>
                      );
                    })()
                  );
                })}
              </Stack>
              {/* <Typography variant="p" component={"p"}>
                            ...
                          </Typography> */}
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                O*NET
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {selectedNetSoc?.OnetSocCode}
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {getOnetTitle(selectedNetSoc?.OnetSocCode)}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              sx={{
                paddingTop: "10px",
                paddingX: "10px",
                paddingBottom: "5px",
              }}
              className="hover:bg-white"
            >
              <Typography variant="p" component={"p"} width={"20%"}>
                SOC Code
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {getSocCode()}
              </Typography>
              <Typography variant="p" component={"p"} width={"20%"}>
                {getSocTitle()}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </TabPanel>
    </Box>
  );
}
