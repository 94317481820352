import React, { useEffect } from "react";
import { setLoading } from "../redux/slices/utilitySlice";
import { useDispatch } from "react-redux";

const IndexDBDataManager = () => {
  var version = 8;
  var dbName = "veToolkitDB";

  const dispatch = useDispatch();

  const isCompleted = {
    DOTMasterTable: false,
    ORSMasterTable: false,
    EnvironmentalDemandLevel: false,
    EnvironmentalDemands: false,
    Aptitudes: false,
    PhysicalDemandLevels: false,
    DotPhysicalDemands: false,
    "2010To2018Soc": false,
    OewsNationalM2022: false,
    OnnetSocConversions: false,
    DotGeds: false,
    DotGedsLevels: false,
    OesCodeDefination: false,
    OapCodeDefinations: false,

    // defination object
    cipDefinitions: false,
    DotTitleIndustrycodes: false,
    DotTitleIndustryCodeV2: false,
    garbled: false,
    GeoTitleDefinitions: false,
    LongDefinationPhysicalEnvironment: false,
    LongDefinations: false,
    niacDefinitionsV2: false,
    OapDefinitions: false,
    SocCodeDefinitions: false,
    socOnetDefinitions: false,
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const openRequest = window.indexedDB.open(dbName, version);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;

      // Define object stores
      const objectStores = [
        { name: "DOTMasterTable", keyPath: "id" },
        { name: "ORSMasterTable", keyPath: "id" },
        { name: "EnvironmentalDemandLevel", keyPath: "id" },
        { name: "EnvironmentalDemands", keyPath: "id" },
        { name: "Aptitudes", keyPath: "id" },
        { name: "PhysicalDemandLevels", keyPath: "id" },
        { name: "DotPhysicalDemands", keyPath: "id" },
        { name: "2010To2018Soc", keyPath: "id" },
        { name: "OewsNationalM2022", keyPath: "id" },
        { name: "OnnetSocConversions", keyPath: "id" },
        { name: "DotGeds", keyPath: "id" },
        { name: "DotGedsLevels", keyPath: "id" },
        { name: "OesCodeDefination", keyPath: "id" },
        { name: "OapCodeDefinations", keyPath: "id" },

        // defination object
        { name: "cipDefinitions", keyPath: "id" },
        { name: "DotTitleIndustrycodes", keyPath: "id" },
        { name: "DotTitleIndustryCodeV2", keyPath: "id" },
        { name: "garbled", keyPath: "id" },
        { name: "GeoTitleDefinitions", keyPath: "id" },
        { name: "LongDefinationPhysicalEnvironment", keyPath: "id" },
        { name: "LongDefinations", keyPath: "id" },
        { name: "niacDefinitionsV2", keyPath: "id" },
        { name: "OapDefinitions", keyPath: "id" },
        { name: "SocCodeDefinitions", keyPath: "id" },
        { name: "socOnetDefinitions", keyPath: "id" },
        //ORS related Stores
        // { name: "OrsPhysicalDemands", keyPath: "id" },
        // { name: "OrsEnvironmentalDemands", keyPath: "id" },
        // { name: "OrsEducationalTrainingDemands", keyPath: "id" },
        // { name: "OrsCognitiveMentalDemands", keyPath: "id" },
        // { name: "OrsFootnoteCodes", keyPath: "id" },
        // { name: "OrsSummaryDemands", keyPath: "id" },
        // { name: "ORSMasterTable", keyPath: "id" },
        // Add more object stores if needed
      ];

      objectStores.forEach(({ name, keyPath }) => {
        if (db.objectStoreNames.contains(name)) db.deleteObjectStore(name);

        const store = db.createObjectStore(name, {
          autoIncrement: true,
          keyPath,
        });
        // Define indexes for the store if needed
        if (name === "OnnetSocConversions") {
          store.createIndex("DotCode", "DotCode", { unique: false });
          store.createIndex("OnetSocCode", "OnetSocCode", { unique: false });
        } else if (name === "DOTMasterTable") {
          store.createIndex("DotCode", "DotCode", { unique: true });
          store.createIndex("Title", "Title", { unique: false });
          store.createIndex("DescriptionCombined", "DescriptionCombined", {
            unique: false,
          });
          store.createIndex("SVP", "SVP", { unique: false });
        } else if (name === "ORSMasterTable") {
          store.createIndex("Soc2018Code", "Soc2018Code", {
            unique: false,
          });
          store.createIndex("EstimateCode", "EstimateCode", {
            unique: false,
          });
        }
      });
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;

      // Fetch and add data for each object store
      const storesData = [
        {
          url: "EnvironmentalDemandLevels.json",
          storeName: "EnvironmentalDemandLevel",
        },
        { url: "EnvironmentalDemands.json", storeName: "EnvironmentalDemands" },
        { url: "aptitudes.json", storeName: "Aptitudes" },
        { url: "PhysicalDemandLevels.json", storeName: "PhysicalDemandLevels" },
        { url: "dotPhysicalDemands.json", storeName: "DotPhysicalDemands" },
        { url: "2010To2018Soc.json", storeName: "2010To2018Soc" },
        { url: "OewsNationalM2022.json", storeName: "OewsNationalM2022" },
        { url: "OnnetSocConversions.json", storeName: "OnnetSocConversions" },
        { url: "DotGeds.json", storeName: "DotGeds" },
        { url: "DotGedsLevels.json", storeName: "DotGedsLevels" },
        { url: "OesCodeDefination.json", storeName: "OesCodeDefination" },
        { url: "OapCodeDefinations.json", storeName: "OapCodeDefinations" },
        { url: "DotMaster.json", storeName: "DOTMasterTable" },
        { url: "GeneratedOrsDataWithDash.json", storeName: "ORSMasterTable" },

        // defination object
        { url: "cipDefinitions.json", storeName: "cipDefinitions" },
        {
          url: "DotTitleIndustrycodes.json",
          storeName: "DotTitleIndustrycodes",
        },
        {
          url: "DotTitleIndustryCodeV2.json",
          storeName: "DotTitleIndustryCodeV2",
        },
        { url: "garbled.json", storeName: "garbled" },
        { url: "GeoTitleDefinitions.json", storeName: "GeoTitleDefinitions" },
        {
          url: "LongDefinationPhysicalEnvironment.json",
          storeName: "LongDefinationPhysicalEnvironment",
        },
        { url: "LongDefinations.json", storeName: "LongDefinations" },
        { url: "niacDefinitionsV2.json", storeName: "niacDefinitionsV2" },
        { url: "OapDefinitions.json", storeName: "OapDefinitions" },
        { url: "SocCodeDefinitions.json", storeName: "SocCodeDefinitions" },
        { url: "socOnetDefinitions.json", storeName: "socOnetDefinitions" },
        // ORS related Database
        // { url: "OrsPhysicalDemands.json", storeName: "OrsPhysicalDemands" },
        // {
        //   url: "OrsEnvironmentalDemands.json",
        //   storeName: "OrsEnvironmentalDemands",
        // },
        // {
        //   url: "OrsEducationalTrainingDemands.json",
        //   storeName: "OrsEducationalTrainingDemands",
        // },
        // {
        //   url: "CofnitiveOrsDemands.json",
        //   storeName: "OrsCognitiveMentalDemands",
        // },
        // { url: "OrsFootnoteCodes.json", storeName: "OrsFootnoteCodes" },
        // { url: "GeneratedOrsDataWithDash.json", storeName: "ORSMasterTable" },
        // { url: "HypotheticalSummeryCodes.json", storeName: "OrsSummaryDemands" },

        // Add more object stores if needed
      ];

      storesData.forEach(({ url, storeName }) => {
        fetchDataAndAddToStore(url, storeName, db);
      });
    };

    openRequest.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequest?.result?.close();
    };
  }, []);

  const fetchDataAndAddToStore = (url, storeName, db) => {
    const transaction = db.transaction([storeName], "readwrite");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.getAll();

    request.onsuccess = function (event) {
      if (event.target.result.length === 0) {
        fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            const transaction = db.transaction([storeName], "readwrite");
            const objectStore = transaction.objectStore(storeName);
            data.forEach((item) => {
              objectStore.add(item);
            });
            transaction.oncomplete = () => {
              // console.log(`Data stored successfully in ${storeName}`);
              isCompleted[storeName] = true;
              const allTrue = Object.values(isCompleted).every(
                (value) => value === true
              );
              if (allTrue) {
                // console.log("data loaded successfully");
                dispatch(setLoading(false));
              }
            };
          })
          .catch((error) => {
            console.error(
              `Error fetching and storing data for ${storeName}:`,
              error
            );
          });
      } else {
        // console.log(
        //   `Data already exists in ${storeName}, skipping data addition.`
        // );
        isCompleted[storeName] = true;
        const allTrue = Object.values(isCompleted).every(
          (value) => value === true
        );
        if (allTrue) {
          // console.log("data loaded successfully");
          dispatch(setLoading(false));
        }
      }
    };

    request.onerror = function (event) {
      console.error(
        `Error retrieving data from ${storeName}:`,
        event.target.error
      );
    };
  };

  return <div></div>;
};

export default IndexDBDataManager;
