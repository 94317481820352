import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    borderRadius: "4px",
    padding: theme.spacing(1),
    boxShadow: theme.shadows[3],
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.grey[900],
  },
}));

const ThemedTooltip = ({
  title,
  children,
  arrow = true,
  placement = "top",
  ...props
}) => {
  return (
    <StyledTooltip title={title} arrow={arrow} placement={placement} {...props}>
      {children}
    </StyledTooltip>
  );
};

export default ThemedTooltip;
