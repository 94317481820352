import { Box, Stack, Typography } from "@mui/material";
import GetDefination from "../../../utils/getDefination";
import { ReactComponent as CodeIcon } from "../../../assets/images/icons/code-lg-icon.svg";
import { useEffect, useState } from "react";
import { handleGetEmploymentEstimateRequest } from "../../../services/getEmployementEstimate";

// we have 2 components for requirements. Other is requirements
const RequirementsTab = ({
  rowData,
  DotData,
  dbCurrentVersion,
  PhysicalDemandData,
  enviromentalData,
  tableDatabyDotCode,
  aptitudeData,
}) => {
  const [EmployEstimate, setEmployEstimate] = useState("");

  useEffect(() => {
    const getEstimateCode = async (dotCode) => {
      const data = await handleGetEmploymentEstimateRequest(dotCode);
      setEmployEstimate(data?.data?.data?.[0]?.dotEstEmp || "--");
    };
    if (rowData) {
      let dotCode = rowData.dotcode || rowData.DotCode || rowData.dotCode;
      dotCode = String(dotCode).replace(/[-.]/g, "");
      getEstimateCode(dotCode);
    }
  }, [rowData]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* <Typography
                        variant="p"
                        component={"p"}
                        color={"#484957"}
                        paddingBottom={"10px"}
                      > */}
      <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
        <Box flexGrow={1}>
          <Typography variant="p" component={"p"} color={"primary"}>
            {rowData?.Title || rowData?.title}
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Typography
            variant="p"
            component={"p"}
            color={"text.grey"}
            sx={{ display: "flex" }}
          >
            <CodeIcon />
            Code
          </Typography>
          <Typography variant="h6" component={"h6"} fontSize={"0.875rem"}>
            {rowData?.DotCodeFormated || rowData?.dotcode}
          </Typography>
        </Stack>
      </Stack>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          maxWidth={"55%"}
          sx={{ paddingTop: "10px", paddingX: "10px" }}
          className="hover:bg-white"
        >
          <Typography variant="p" component={"p"}>
            Employment Estimate
          </Typography>
          <Typography variant="p" component={"p"}>
            {EmployEstimate
              ? Number(EmployEstimate)?.toLocaleString("en-US")
              : "--"}
          </Typography>
        </Stack>
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          maxWidth={"55%"}
          sx={{ paddingTop: "10px", paddingX: "10px" }}
          className="hover:bg-white"
        >
          <Typography variant="p" component={"p"}>
            SVP
          </Typography>
          <Typography variant="p" component={"p"}>
            {rowData.svp || rowData.SVP}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          maxWidth={"55%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingTop: "10px", paddingX: "10px" }}
          className="hover:bg-white"
        >
          <Typography variant="p" component={"p"}>
            Physical Demand
          </Typography>
          <Typography variant="p" component={"p"}>
            {rowData.physical || rowData.Strength}
          </Typography>
        </Stack>
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            GED:
          </Typography>
        </Stack>
        {DotData?.map((data, index) => (
          <Stack
            key={index}
            direction={"row"}
            maxWidth={"55%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ paddingTop: "10px", paddingX: "10px" }}
            className="hover:bg-white"
          >
            <Typography variant="p" component={"p"}>
              <GetDefination
                {...{
                  dbCurrentVersion,
                  TypeName: "GED",
                  Description: data.name,
                  Code: data.name[0] + data.value,
                }}
              >
                {data.name}
              </GetDefination>
            </Typography>
            <Typography variant="p" component={"p"}>
              {data.value}
            </Typography>
          </Stack>
        ))}
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Physical Demands:
          </Typography>
        </Stack>
        {PhysicalDemandData?.map((data, index) => (
          <Stack
            key={index}
            maxWidth={"55%"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ paddingTop: "10px", paddingX: "10px" }}
            className="hover:bg-white"
          >
            <Typography variant="p" component={"p"}>
              <GetDefination
                {...{
                  dbCurrentVersion,
                  TypeName: "Physical Demands",
                  Description: data.name,
                  Code: data.value,
                }}
              >
                {data.name}
              </GetDefination>
            </Typography>
            <Typography variant="p" component={"p"}>
              {data.value}
            </Typography>
          </Stack>
        ))}
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Environmental:
          </Typography>
        </Stack>
        {enviromentalData?.map(
          (data, index) =>
            data.name !== undefined &&
            data.value !== undefined && (
              <Stack
                key={index}
                direction={"row"}
                maxWidth={"55%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ paddingTop: "10px", paddingX: "10px" }}
                className="hover:bg-white"
              >
                <Typography variant="p" component={"p"}>
                  <GetDefination
                    {...{
                      dbCurrentVersion,
                      TypeName: "Environmental",
                      Description: data.name,
                      Code: data.value,
                    }}
                  >
                    {data.name}
                  </GetDefination>
                </Typography>
                <Typography variant="p" component={"p"}>
                  {data.value}
                </Typography>
              </Stack>
            )
        )}
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
          >
            Temperaments:
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            paddingTop: "10px",
            paddingX: "10px",
            paddingBottom: "5px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            className="flex flex-col w-full"
          >
            {Array.from(
              tableDatabyDotCode?.Temper ? tableDatabyDotCode?.Temper : ""
            )?.map((data, index) => (
              <span key={index} className="w-full">
                <div className="w-10">
                  <GetDefination
                    {...{
                      dbCurrentVersion,
                      TypeName: "Temperaments",
                      Description: "NULL",
                      Code: data,
                    }}
                  >
                    {data}
                  </GetDefination>
                </div>
              </span>
            ))}
          </Typography>
        </Stack>
      </Box>
      <Box
        borderRadius={"10px"}
        backgroundColor={"#F5F5F5"}
        marginBottom={"5px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderBottom: "1px solid #666672",
            padding: "10px",
          }}
        >
          <Typography
            variant="p"
            component={"p"}
            color={"primary"}
            fontWeight={"bold"}
            fontSize={"15px"}
            width={"60%"}
          >
            Aptitudes:
          </Typography>

          {/* <Typography
                            variant="p"
                            component={"p"}
                            color={"primary"}
                            fontWeight={"bold"}
                            fontSize={"15px"}
                          >
                            DOT
                          </Typography> */}
        </Stack>

        {aptitudeData?.map((data, index) => (
          <Stack
            key={index}
            direction={"row"}
            maxWidth={"55%"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              paddingTop: "10px",
              paddingX: "10px",
              paddingBottom: "5px",
            }}
            className="hover:bg-white"
          >
            <Typography variant="p" component={"p"}>
              <GetDefination
                {...{
                  dbCurrentVersion,
                  TypeName: "Aptitudes",
                  Description: data.description,
                  Code: "",
                }}
              >
                {data.description}
              </GetDefination>
            </Typography>
            <Typography variant="p" component={"p"}>
              {data.value}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
};

export default RequirementsTab;
