import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

const ThemesCheckbox = ({ name, label, checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          checked={checked}
          name={name}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

export default ThemesCheckbox;
